import React from "react"
import { Link } from "gatsby"

function Apply({ jobs }) {
    return(
        <div>
            <section className="tagLine">
                <div className="introText">
                    <h2>
                        Our games have generated <em>millions</em> of <em>organic downloads</em> worldwide.
                    </h2>
                    <br />
                    <p>
                    <b>Funovus</b> is a game development studio based in the United States with team members around the globe.
                    </p>
                    <br />
                    <p> 
                    We develop games in <b>small</b>, <b>lean</b>, and <b>agile</b> teams. If you have the passion, the drive, and the vision to build a game from start to finish, please apply today!
                    </p>
                    <br />
                    <div style={{ display: 'flex', alignSelf: 'flexStart' }}>
                        <h3 style={{ color: '#ff5f08', flexShrink: 0 }}>Our Values</h3>
                        <div style={{ borderLeft: '2px solid #ff5f08', paddingLeft: "1.5rem", marginLeft: "1.5rem" }}>
                                <p>We are an ambitious and tight-knit team of developers.</p>
                                <p>We are looking for those who are determined, fierce, and capable.</p>
                                <p>Those who create new options out of necessity where none existed before.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="envImg">
                <img src="https://i.imgur.com/QRCHPnX.png" />
                <img src="https://i.imgur.com/ECfIgi5.png" />
                <img src="https://i.imgur.com/IXTLAym.png" />
            </div>
            <section className="values">
                <div>
                    <div style={{ display: 'flex', alignSelf: 'flexStart' }}>
                        <h3 style={{ color: '#ff5f08', flexShrink: 0 }}>Benefits</h3>
                        <div style={{ borderLeft: '2px solid #ff5f08', paddingLeft: "1.5rem", marginLeft: "1.5rem" }}>
                                <p>Free meals, free gym, free snacks;</p>
                                <p>100% company-covered medical, dental, and vision insurance;</p>
                                <p>H1B and green card sponsorship for all international graduates;</p>
                        </div>
                    </div>
                    <div className="valuePoints">
                        <div className="value">
                            <div className="valueImg">
                                <img src="https://i.imgur.com/uUlT8an.png" width="250" height="250" alt="value"/>
                            </div>
                            <h3>Make & Keep Promises</h3>
                            <p>We must be able to trust and rely on each other to excel. One of the best ways to build trust is to make and keep promises, no matter how small they are.</p>
                        </div>
                        <div className="value">
                            <div className="valueImg">
                                <img src="https://i.imgur.com/7buDAQ8.png" width="250" height="250" alt="value"/>
                            </div>
                            <h3>Be Authentic</h3>
                            <p> Direct and honest opinions—while sometimes hard to share and receive—are critical for high performing teams to grow.</p>
                        </div>
                        <div className="value">
                            <div className="valueImg">
                                <img src="https://i.imgur.com/j3xu508.png" width="265" height="250" alt="value"/>
                            </div>
                            <h3>Work and… Play!</h3>
                            <p>We don't just make games together... we share and play them together too! We regularly organize game nights and provide an annual games stipend for the team.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="positions">
                <div>
                    <div style={{ display: 'flex', alignSelf: 'flex-start' }}>
                        <h3 style={{ color: '#ff5f08', flexShrink: 0 }}>Open Positions</h3>
                        <div style={{ borderLeft: '2px solid #ff5f08', paddingLeft: "1.5rem", marginLeft: "1.5rem" }}>
                            <p>All positions provide visa sponsorship for international students in the U.S.</p>
                        </div>
                    </div>
                    <div className="jobCards">
                        {
                            jobs.map(({ node: job }) => <Link key={job.frontmatter.title} className="jobCard" to={`${job.frontmatter.path}`}>
                            <h5>{job.frontmatter.title}</h5>
                        </Link>)
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Apply