import React from "react"
import { graphql } from "gatsby"

import Nav from "../../Components/Nav"
import SEO from "../../Components/SEO"
import Apply from "../../Components/Apply"
import Footer from "../../Components/Footer"

const CareersPage = ({data}) => {
  const { edges: jobs } = data.allMarkdownRemark
  return (
    <main className="careerPage">
      <SEO title="Funovus Career Page"
        description={`Funovus is a game development studio based in the United States, with team members around the globe, embracing a remote-working, geo-diverse team and culture. Some of our positions are remote-friendly, and all positions provide visa sponsorship for international students in the U.S.`}
        keywords="hiring, career, 3d artist, 2d artist, art director, apply jobs, technical artist, environment artist, art out source manager, ui artist"
      />
      <Nav />
      <Apply jobs={jobs}/>
      <Footer />
    </main>
  );
}

export default CareersPage

export const pageQuery = graphql`
  query CareersQuery {
    allMarkdownRemark(filter: {
        fileAbsolutePath: {
           regex: "/(jobs)/"
        }
     }, sort: { order: ASC, fields: [frontmatter___order] }) {
      edges {
        node {
          id
          frontmatter {
            title
            type
            path
            applylink
          }
        }
      }
    }
  }
`
